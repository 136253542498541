<template>
  <div class="index">
    <div class="content-box">
      <div class="search-box">
        <img
          class="search-img"
          src="@/assets/realTimeInfo/banner2.png"
          alt=""
        />
        <div class="search">
          <div class="input-box">
            <el-input
              v-model="searchForm.keywords"
              placeholder="请输入搜索关键字.."
              :clearable="true"
              @clear="initPage"
            ></el-input>
          </div>
          <div class="search-btn" @click="initPage">搜索</div>
        </div>
      </div>
      <div class="dynamic">
        <div class="dynamic-list">
          <div v-for="(item, index) in dataList" :key="index">
            <list-item :item="item" />
          </div>
          <div class="kong" v-if="dataList.length <= 0">
            <el-empty
              image="https://eduapi.114kaili.com/Content/images/no_data.png"
              :image-size="303"
              description="暂无数据"
            ></el-empty>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            @current-change="handleCurrentChange"
            :background="true"
            @prev-click="handleCurrentChange"
            @next-click="handleCurrentChange"
            :page-size="dataListParams.pageSize"
            layout="total, prev, pager, next"
            :total="dataListParams.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listItem from "./compontent/list-item";
import tableMinxin from "@/mixins/tableMinxin.js";
export default {
  mixins: [tableMinxin],
  components: {
    listItem,
  },
  data() {
    return {
      searchForm: {
        type: "",
        keywords: "",
      },
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.searchForm.type = this.$route.query.type;
    }
    this.initPage();
  },
  methods: {
    initPage() {
      this._init_data("News", this.searchForm);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 50px;
  font-size: 15px;
  border: none;
}
::v-deep .el-input__suffix {
  .el-icon-circle-close:before {
    position: relative;
    top: 4px;
    font-size: 20px;
  }
}
.kong {
  width: 100%;
  padding: 100px 0;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
}
.index {
  width: 100%;
  background: url("../../assets/realTimeInfo/banner.png") no-repeat;
  background-color: #f5f5f5;
  background-position: center top;
  .content-box {
    width: 1200px;
    margin: 0 auto;
    .search-box {
      padding-top: 30px;
      width: 603px;
      display: flex;
      flex-direction: column;
      .search-img {
        width: 60%;
        height: auto;
      }
      .search {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding-top: 25px;
        .input-box {
          flex: 1;
          height: 100%;
          border-radius: 10px 0 0 10px;
          overflow: hidden;
        }
        .search-btn {
          width: 115px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #0086ff;
          color: #fff;
          font-size: 19px;
          border-radius: 0px 10px 10px 0px;
          cursor: pointer;
        }
      }
    }
    .dynamic {
      width: 100%;
      margin-top: 90px;
      .dynamic-list {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .pagination {
        padding: 58px 0 70px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
